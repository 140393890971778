import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isEmpty, numberWithCommas, smartRound } from '@/util';
import { balanceToRow } from '../util';
import AssetAreaChart from './AssetAreaChart';
import { MarketTypeTable } from './MarketTypeTable';

function pnlDisplayValue(value) {
  let color = 'text.primary';
  if (value > 0) {
    color = 'success.main';
  }
  if (value < 0) {
    color = 'error.main';
  }

  return (
    <Stack alignItems='baseline' direction='row' spacing={4}>
      <Typography color={color} variant='h3'>
        {value > 0 ? '+' : ''}
        {numberWithCommas(smartRound(value, 2))}
      </Typography>
      <Typography color={color} variant='h6'>
        USDT
      </Typography>
    </Stack>
  );
}

function pnlDisplayPercentage(value) {
  if (!value) {
    return null;
  }

  let color = 'text.primary';
  let transform = 'none';
  if (value > 0) {
    color = 'success.main';
  } else if (value < 0) {
    color = 'error.main';
    transform = 'rotate(180deg) translate(-30%, 0)';
  }

  return (
    <Stack alignItems='baseline' direction='row' spacing={2}>
      <ArrowOutwardIcon
        sx={{
          alignSelf: 'center',
          color,
          transform,
        }}
      />
      <Typography color={color} variant='subtitle1'>
        {`${Number(value).toFixed(2)}%`}
      </Typography>
      <Typography variant='subtitle1'>7d</Typography>
    </Stack>
  );
}

const renderTableType = (assets, accountBalances, selectedAccountObj) => {
  // Stashing ExhangTypeTable for when All Accounts is implemented
  // if (activeButton === 0) {
  //   return ExchangeTypeTable(accountBalances, selectedAccountObj);
  // }
  return MarketTypeTable(assets);
};

const calculatePnl = (assets) => {
  return assets.reduce((acc, asset) => {
    if (asset.unrealized_profit) {
      return acc + asset.unrealized_profit;
    }
    return acc;
  }, 0);
};

function AccountOverviewComponent({
  accountBalances,
  selectedAccount,
  assets,
  pastSnapshots,
  balanceHistory,
}) {
  const selectedAccountObj = accountBalances.find(
    (obj) => obj.account_id === selectedAccount[0]
  );

  const row = balanceToRow(selectedAccountObj, pastSnapshots);
  const totalPnl = calculatePnl(selectedAccountObj.assets);

  const { weekAgoDiffPercentage } = row;

  return (
    <Stack direction='column' margin={6} spacing={4}>
      <Card>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Typography color='text.subtitle' variant='h6'>
              Total Balance
            </Typography>
            <Stack alignItems='baseline' direction='row' spacing={4}>
              <Typography variant='h1'>
                {numberWithCommas(
                  smartRound(selectedAccountObj?.totalValue, 2)
                )}
              </Typography>
              <Typography variant='h6'>USDT</Typography>
              {pnlDisplayPercentage(weekAgoDiffPercentage)}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack direction='column' spacing={4}>
            <Stack direction='column' spacing={2}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography color='text.subtitle' variant='h6'>
                  Unrealized PnL
                </Typography>
              </Stack>
              {pnlDisplayValue(totalPnl)}
            </Stack>
            <Typography color='text.subtitle' variant='h6'>
              Notional Exposure
            </Typography>
            <AssetAreaChart
              balanceData={balanceHistory}
              selectedAccount={selectedAccount}
            />
          </Stack>
        </CardContent>
      </Card>

      <Stack direction='column' spacing={4}>
        {isEmpty(assets) ? (
          <Box
            alignItems='center'
            display='flex'
            height='60vh'
            justifyContent='center'
            width='100%'
          >
            <Typography variant='h5'>No assets found</Typography>
          </Box>
        ) : (
          renderTableType(assets, accountBalances, selectedAccountObj)
        )}
      </Stack>
    </Stack>
  );
}

export default AccountOverviewComponent;
