import { replaceBigInts } from '@/shared/bigIntUtils';
import { Loader } from '@/shared/Loader';
import { Alert, Box, Chip, Link, Typography } from '@mui/material';
import { insertEllipsis } from '../insertEllipsis';
import useProofDetails from '../proofUtils/useProofDetails';

function RiskEventItem({ txHash, config }) {
  const {
    proofData: riskEvent,
    loading,
    error,
  } = useProofDetails(txHash, config);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert severity='error' sx={{ mb: 2 }}>
        Failed to load risk attestation. Please try again later.
      </Alert>
    );
  }

  if (!riskEvent) {
    return (
      <Alert severity='info' sx={{ mb: 2 }}>
        No risk attestation data found.
      </Alert>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <Chip
          color={riskEvent.eventColor || 'primary'}
          label={riskEvent.eventName || 'Risk Event'}
          sx={{ borderRadius: 1 }}
        />
        <Box>
          <Typography color='text.secondary' variant='caption'>
            Volume
          </Typography>
          <Typography variant='h6'>
            {riskEvent.data?.toLocaleString()}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 1,
          p: 2,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography color='text.secondary' variant='caption'>
            Block
          </Typography>
          <Typography variant='h6'>
            {riskEvent.blockNumber?.toLocaleString()}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography color='text.secondary' variant='caption'>
            Transaction
          </Typography>
          <Typography variant='h6'>
            <Link
              href={`${config.explorerUrl}/tx/${riskEvent.transactionHash}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              {insertEllipsis(riskEvent.transactionHash, 8, 6)}
            </Link>
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography color='text.secondary' variant='caption'>
            Attester
          </Typography>
          <Typography variant='h6'>
            {insertEllipsis(riskEvent.attester)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function RiskEventsSection({ txHashes, config }) {
  if (!txHashes?.length) {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 2 }} variant='h5'>
          Risk Attestations
        </Typography>
        <Alert severity='info'>
          No risk attestations available for this epoch.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 2 }} variant='h5'>
        Risk Attestations
      </Typography>
      {txHashes.map((hash) => (
        <RiskEventItem config={config} key={hash} txHash={hash} />
      ))}
    </Box>
  );
}
