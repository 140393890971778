import { Loader } from '@/shared/Loader';
import { Alert, Box, Chip, Link, Typography } from '@mui/material';
import { insertEllipsis } from '../insertEllipsis';
import useProofDetails from '../proofUtils/useProofDetails';

/**
 * Component that displays data attestation events for a specific trader epoch.
 * Shows details like merkle hash, CID, block number, transaction hash and attester address.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.txHash - Transaction hash to fetch data for
 * @param {Object} props.config - Configuration object for blockchain connection
 * @returns {React.ReactElement} Data attestation section
 */
export default function DataEventSection({ txHash, config }) {
  const {
    proofData: dataEvent,
    loading,
    error,
  } = useProofDetails(txHash, config);

  if (!txHash) {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 2 }} variant='h5'>
          Data Attestation
        </Typography>
        <Alert severity='info'>
          No data attestation available for this epoch.
        </Alert>
      </Box>
    );
  }

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <Alert severity='error'>
          Failed to load data attestation. Please try again later.
        </Alert>
      );
    }

    if (!dataEvent) {
      return <Alert severity='info'>No data attestation data found.</Alert>;
    }

    return (
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Chip
            color={dataEvent.eventColor || 'primary'}
            label={dataEvent.eventName || 'Data Event'}
            sx={{ borderRadius: 1 }}
          />
          <Box>
            <Typography color='text.secondary' variant='caption'>
              Merkle Hash
            </Typography>
            <Typography variant='h6'>
              {insertEllipsis(dataEvent.data.merkleRoot, 8, 6)}
            </Typography>
          </Box>
          <Box>
            <Typography color='text.secondary' variant='caption'>
              CID
            </Typography>
            <Typography variant='h6'>
              {insertEllipsis(dataEvent.data.cid, 8, 6)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: 1,
            p: 2,
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography color='text.secondary' variant='caption'>
              Block
            </Typography>
            <Typography variant='h6'>
              {dataEvent.blockNumber?.toLocaleString()}
            </Typography>
          </Box>

          <Box sx={{ mb: 1 }}>
            <Typography color='text.secondary' variant='caption'>
              Transaction
            </Typography>
            <Typography variant='h6'>
              <Link
                href={`${config.explorerUrl}/tx/${dataEvent.transactionHash}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                {insertEllipsis(dataEvent.transactionHash, 8, 6)}
              </Link>
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography color='text.secondary' variant='caption'>
              Attester
            </Typography>
            <Typography variant='h6'>
              {insertEllipsis(dataEvent.attester)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 2 }} variant='h5'>
        Data Attestation
      </Typography>
      {renderContent()}
    </Box>
  );
}
