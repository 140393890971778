import { AccountsContext } from '@/shared/context/AccountsProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import {
  StyledHeaderTableCellWithLine,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import basescanLight from '../../../images/logos/basescan-light.png';
import { insertEllipsis } from './insertEllipsis';
import { useProofsPagination } from './proofUtils/useProofsPagination';
import { TraderIdAutocomplete } from './TraderIdAutocomplete';

const columns = [
  { id: 'expand', label: '', width: 30, align: 'center' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'traderId', label: 'Trader ID', width: 60, align: 'left' },
  {
    id: 'dataAttestation',
    label: 'Data Attestation',
    width: 260,
    align: 'left',
  },
  {
    id: 'riskCount',
    label: 'Latest Risk Event',
    width: 260,
    align: 'left',
  },
  { id: 'actions', label: 'Actions', width: 30, align: 'center' },
];

/**
 * Row component that displays proof event data with expandable risk events
 */
function ProofRow({ proof }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { dataEvent, riskEvents, traderId, epoch } = proof;

  const latestRiskEvent =
    riskEvents?.length > 0
      ? riskEvents.reduce((latest, current) =>
          current.blockNumber > latest.blockNumber ? current : latest
        )
      : null;

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>

        <StyledTableCell>{epoch?.toLocaleString()}</StyledTableCell>
        <StyledTableCell>{insertEllipsis(traderId)}</StyledTableCell>

        <StyledTableCell>
          <Stack spacing={1}>
            <Box>
              <Typography color='text.secondary' variant='caption'>
                Merkle Hash
              </Typography>
              <Typography variant='body2'>
                {insertEllipsis(dataEvent.data.merkleRoot, 8, 6)}
              </Typography>
            </Box>
            <Box>
              <Typography color='text.secondary' variant='caption'>
                CID
              </Typography>
              <Typography variant='body2'>
                {insertEllipsis(dataEvent.data.cid, 8, 6)}
              </Typography>
            </Box>
          </Stack>
        </StyledTableCell>

        <StyledTableCell>
          <Stack spacing={1}>
            <Box>
              <Typography color='text.secondary' variant='caption'>
                Risk Events ({riskEvents?.length || 0})
              </Typography>
              {latestRiskEvent && (
                <Typography variant='body2'>
                  Latest Volume: {latestRiskEvent.data?.toLocaleString()}
                </Typography>
              )}
            </Box>
          </Stack>
        </StyledTableCell>

        <StyledTableCell align='center'>
          <Button
            size='small'
            variant='text'
            onClick={() =>
              navigate(`/explorer/trader-epoch/${traderId}/${epoch}`)
            }
          >
            View details
          </Button>
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <StyledTableCell
          colSpan={6}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse unmountOnExit in={open} timeout='auto'>
            <Box sx={{ margin: 2 }}>
              <Typography gutterBottom variant='subtitle2'>
                Data Attestation Details
              </Typography>
              <Box sx={{ mb: 2, pl: 2 }}>
                <Typography display='block' variant='caption'>
                  Block: {dataEvent.blockNumber.toLocaleString()}
                </Typography>
                <Typography display='block' variant='caption'>
                  <Link
                    href={`https://basescan.org/tx/${dataEvent.transactionHash}`}
                  >
                    Txn: {insertEllipsis(dataEvent.transactionHash, 8, 6)}
                  </Link>
                </Typography>
                <Typography display='block' variant='caption'>
                  Attester: {insertEllipsis(dataEvent.attester)}
                </Typography>
              </Box>

              <Typography gutterBottom variant='subtitle2'>
                Risk Events ({riskEvents?.length || 0})
              </Typography>
              {riskEvents?.length > 0 ? (
                riskEvents.map((riskEvent, index) => (
                  <Box
                    key={`${riskEvent.blockNumber}:${riskEvent.transactionHash}`}
                    sx={{ mb: index < riskEvents.length - 1 ? 2 : 0 }}
                  >
                    <Typography variant='body2'>
                      Volume: {riskEvent.data?.toLocaleString()}
                    </Typography>
                    <Typography
                      display='block'
                      sx={{ mt: 1 }}
                      variant='caption'
                    >
                      Block: {riskEvent.blockNumber?.toLocaleString()}
                      <br />
                      <Link
                        href={`https://basescan.org/tx/${riskEvent.transactionHash}`}
                      >
                        Txn: {insertEllipsis(riskEvent.transactionHash, 8, 6)}
                      </Link>
                      <br />
                      Attester: {insertEllipsis(riskEvent.attester)}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography color='text.secondary'>
                  No risk events found
                </Typography>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

/**
 * Component that displays a table of proof events from the blockchain
 */
export default function ExplorerProofsTable({ pageView }) {
  const pageSize = pageView ? 10 : 25;
  const [traderIdFilter, setTraderIdFilter] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  const { proofs, page, loading, handlePageChange, totalItems } =
    useProofsPagination({ pageSize });

  // Filter proofs by trader ID if filter is set
  const filteredProofs = traderIdFilter
    ? proofs.filter((proof) => matchesTraderId(proof.traderId, traderIdFilter))
    : proofs;

  return (
    <Card sx={{ height: '95%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        alignItems='center'
        direction='row'
        spacing={2}
        style={{ paddingLeft: '20px', paddingTop: '20px', flexShrink: 0 }}
      >
        <img alt='Base Scan' src={basescanLight} style={{ height: '30px' }} />
        <Typography variant='h4'>Oracle</Typography>
      </Stack>
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ mb: 2, flexShrink: 0 }}>
          <TraderIdAutocomplete
            accounts={accounts}
            value={traderIdFilter}
            onChange={(event, newValue) => {
              setTraderIdFilter(newValue || '');
            }}
            onInputChange={(event, newInputValue) => {
              setTraderIdFilter(newInputValue);
            }}
          />
        </Box>

        <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledHeaderTableCellWithLine
                    align={column.align}
                    key={column.id}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </StyledHeaderTableCellWithLine>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <StyledTableCell align='center' colSpan={6}>
                    <ScaleLoader color={theme.palette.primary.main} />
                  </StyledTableCell>
                </TableRow>
              )}
              {!loading && filteredProofs.length === 0 && (
                <TableRow>
                  <StyledTableCell align='center' colSpan={6}>
                    No proofs found
                  </StyledTableCell>
                </TableRow>
              )}
              {!loading &&
                filteredProofs.length > 0 &&
                filteredProofs.map((proof) => (
                  <ProofRow
                    key={`${proof.traderId}-${proof.epoch}`}
                    proof={proof}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {pageView ? (
          <Box sx={{ flexShrink: 0, mt: 2 }}>
            <Button
              sx={{
                width: '100%',
                height: '50px',
                border: 0,
                color: theme.palette.text.offWhite,
                borderColor: theme.palette.text.offWhite,
              }}
              variant='outlined'
              onClick={() => navigate('/explorer/proofs')}
            >
              View all proofs
            </Button>
          </Box>
        ) : (
          <TablePagination
            component='div'
            count={-1}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            sx={{ flexShrink: 0 }}
            onPageChange={handlePageChange}
          />
        )}
      </CardContent>
    </Card>
  );
}
