import { useEffect, useState, useCallback, useContext } from 'react';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { getAutoOrderConfig } from '@/apiServices';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import defaultStrategySettings from '@/pages/dashboard/defaultStrategySettings';

function useAutoOrderEntryForm({ trajectories, accounts }) {
  const { showAlert } = useContext(ErrorContext);

  const {
    // Base fields
    selectedPair,
    selectedAccounts,
    selectedSide,
    baseQty,
    convertedQty,

    // Auto order fields
    selectedDuration,
    setSelectedDuration,
    passiveness,
    setPassiveness,
    alphaTilt,
    setAlphaTilt,
    trajectory,
    setTrajectory,
    setSelectedStrategy,

    // Pre trade data
    preTradeEstimationData,
    preTradeDataError,
    preTradeDataLoading,
  } = useOrderForm();

  // Auto order state
  const [urgency, setUrgency] = useState('MEDIUM');
  const [enableUrgency, setEnableUrgency] = useState(false);
  const [isAutoOrderFormLoading, setIsAutoOrderFormLoading] = useState(false);
  const [autoOrderConfig, setAutoOrderConfig] = useState(null);
  const [autoOrderExplanation, setAutoOrderExplanation] = useState(null);

  const setDefaultConfig = () => {
    setSelectedDuration(0);
    setPassiveness(defaultStrategySettings.passiveness);
    setAlphaTilt(defaultStrategySettings.alphaTilt);
    setTrajectory('');
    setSelectedStrategy('');
    setAutoOrderExplanation('');
  };

  const fetchAutoOrderConfig = useCallback(async () => {
    try {
      const response = await getAutoOrderConfig(
        urgency,
        selectedAccounts.map((a) => accounts[a].exchangeName),
        selectedPair.id,
        baseQty || convertedQty,
        selectedSide
      );
      setAutoOrderConfig(response.config);
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch auto order config: ${error.message}`,
      });
    } finally {
      setIsAutoOrderFormLoading(false);
    }
  }, [
    urgency,
    selectedAccounts,
    selectedPair,
    baseQty,
    convertedQty,
    selectedSide,
  ]);

  useEffect(() => {
    setEnableUrgency(
      !!selectedPair &&
        selectedAccounts.length > 0 &&
        !!selectedSide &&
        (!!baseQty || !!convertedQty)
    );
  }, [selectedPair, selectedAccounts, selectedSide, baseQty, convertedQty]);

  useEffect(() => {
    if (!enableUrgency) {
      setDefaultConfig();
    }
  }, [enableUrgency]);

  useEffect(() => {
    if (enableUrgency && urgency) {
      setIsAutoOrderFormLoading(true);
      fetchAutoOrderConfig();
    }
  }, [
    enableUrgency,
    urgency,
    selectedPair,
    baseQty,
    convertedQty,
    selectedSide,
  ]);

  useEffect(() => {
    // set to defaults first
    setDefaultConfig();

    Object.entries(autoOrderConfig || {}).forEach(([key, value]) => {
      if (key === 'engine_passiveness') setPassiveness(value);
      else if (key === 'alpha_tilt') setAlphaTilt(value);
      else if (key === 'strategy') {
        const trajectory_id = Object.values(trajectories).find(
          (t) => t.name === value
        ).id;
        setTrajectory(trajectory_id);
      } else if (key === 'duration') setSelectedDuration(value);
      else if (key === 'explanation') setAutoOrderExplanation(value);
    });
  }, [autoOrderConfig]);

  const configFields = {
    passiveness,
    setPassiveness,
    alphaTilt,
    setAlphaTilt,
    trajectory,
    setTrajectory,
    selectedDuration,
    setSelectedDuration,
  };

  return {
    urgency,
    setUrgency,
    enableUrgency,
    isAutoOrderFormLoading,
    autoOrderConfig,
    configFields,
    autoOrderExplanation,
    preTradeEstimationData,
    preTradeDataError,
    preTradeDataLoading,
  };
}

export default useAutoOrderEntryForm;
