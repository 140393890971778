import { deserializeBigInt, serializeBigInt } from '@/shared/bigIntUtils';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';

// Create persistent atoms with error handling
const proofsAtom = atomWithStorage('taas-proofs-cache', [], {
  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item, deserializeBigInt) : [];
    } catch (error) {
      // Continue with in-memory state even if persistence fails
      console.error('Error reading from localStorage:', error);
      return [];
    }
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value, serializeBigInt));
    } catch (error) {
      // Continue with in-memory state even if persistence fails
      console.error('Error writing to localStorage:', error);
    }
  },
});

const currentPageAtom = atomWithStorage('taas-proofs-current-page', 0, {
  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : 0;
    } catch (error) {
      console.error('Error reading page from localStorage:', error);
      return 0;
    }
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // Continue with in-memory state even if persistence fails
      console.error('Error writing page to localStorage:', error);
    }
  },
});

// Create derived atom for sorted proofs
const sortedProofsAtom = atom((get) => {
  const proofs = get(proofsAtom);
  return [...proofs].sort((a, b) => {
    if (a.epoch !== b.epoch) {
      const epochA = Number(a.epoch);
      const epochB = Number(b.epoch);
      return epochB - epochA;
    }
    return b.traderId.localeCompare(a.traderId);
  });
});

export function useProofsCache() {
  const [proofs, setProofs] = useAtom(proofsAtom);
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const [sortedProofs] = useAtom(sortedProofsAtom);

  const updateProofs = useCallback(
    (newProofs) => {
      setProofs((currentProofs) => {
        const proofsMap = new Map(
          currentProofs.map((proof) => [
            `${proof.traderId}-${proof.epoch}`,
            proof,
          ])
        );

        newProofs.forEach((newProof) => {
          const processedProof = JSON.parse(
            JSON.stringify(newProof, serializeBigInt)
          );
          Object.keys(processedProof).forEach((key) => {
            processedProof[key] = deserializeBigInt(null, processedProof[key]);
          });
          const key = `${processedProof.traderId}-${processedProof.epoch}`;
          proofsMap.set(key, processedProof);
        });

        return Array.from(proofsMap.values());
      });
    },
    [setProofs]
  );

  return {
    proofs,
    sortedProofs,
    currentPage,
    updateProofs,
    updateCurrentPage: setCurrentPage,
    proofsLength: proofs.length,
  };
}
