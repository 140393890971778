const palette = {
  base: {
    white: '#E6E6E6',
    black: '#1A1A1A',
  },
  brand: {
    0: '#ffb56a00',
    25: '#fff5ea',
    50: '#fff8ed',
    100: '#ffefd4',
    200: '#ffdca8',
    300: '#ffc171',
    400: '#ffad59',
    500: '#ff9830',
    600: '#ee8536',
    700: '#b75123',
    800: '#91411e',
    900: '#75371a',
    950: '#3f190b',
  },
  green: {
    25: '#f6fefc',
    50: '#f0fdf9',
    100: '#ccfbef',
    200: '#99f6e0',
    300: '#5fe9d0',
    400: '#2ed3b7',
    500: '#15b79e',
    600: '#0e9384',
    700: '#107569',
    800: '#125d56',
    900: '#134e48',
    950: '#0a2926',
  },
  blue: {
    25: '#f5f8ff',
    50: '#eff4ff',
    100: '#d1e0ff',
    200: '#b2ccff',
    300: '#84adff',
    400: '#528bff',
    500: '#2970ff',
    600: '#155eef',
    700: '#004eeb',
    800: '#0040c1',
    900: '#00359e',
    950: '#002266',
  },
  error: {
    25: '#fffbfa',
    50: '#fff1f2',
    100: '#ffe2e4',
    200: '#ffcacd',
    300: '#fea4a9',
    400: '#fd7279',
    500: '#f74551',
    600: '#e33135',
    700: '#bf262a',
    800: '#9e2124',
    900: '#822024',
    950: '#480b0d',
  },
  warning: {
    25: '#fffbeb',
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
    950: '#451a03',
  },
  success: {
    25: '#f6fef9',
    50: '#f1fcf8',
    100: '#d1f6ed',
    200: '#a2edda',
    300: '#6ddcc4',
    400: '#3fc4ac',
    500: '#23ab94',
    600: '#1c8777',
    700: '#1a6c61',
    800: '#19564f',
    900: '#194942',
    950: '#092b28',
  },
  grey: {
    25: '#fafafa',
    50: '#f7f7f7',
    100: '#f0f0f1',
    200: '#ececed',
    300: '#cecfd2',
    400: '#94979c',
    500: '#85888e',
    600: '#61656c',
    700: '#373a41',
    800: '#22262f',
    900: '#13161b',
  },
  black: {
    25: '#f0f0f0',
    50: '#e6e6e6',
    100: '#cccccc',
    200: '#b3b3b3',
    300: '#808080',
    400: '#666666',
    500: '#4d4d4d',
    600: '#333333',
    700: '#1a1a1a',
    800: '#121212',
    900: '#000000',
  },
};

export default palette;
