/* eslint-disable import/no-import-module-exports */

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Highcharts from 'highcharts/highstock';
import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { OrderFormProvider } from '@/shared/context/OrderFormProvider';
import { getVersionData } from './apiServices';
import AccountBalancePage from './pages/accountBalance/AccountBalancePage';
import AccountSettings from './pages/accountSettings/AccountSettings';
import ChainedOrderEntryPage from './pages/chainedOrders/ChainedOrdersEntryPage';
import OrderEntryPage from './pages/dashboard/OrderEntryPage';
import KeyManagementPage from './pages/keyManagement/KeyManagementPage';
import MultiOrderEntryPage from './pages/multiOrder/MultiOrderEntryPage';
import OrderDetailsPage from './pages/orderDetails/algoOrderDetails/OrderDetailsPage';
import OrderSuperDetailPage from './pages/orderDetails/algoOrderDetails/OrderSuperDetailPage';
import MultiOrderDetailsPage from './pages/orderDetails/multiOrderDetails/MultiOrderDetailsPage';
import SimpleOrderDetailsPage from './pages/orderDetails/simpleOrderDetails/SimpleOrderDetailsPage';
import OrderViewPage from './pages/orderView/OrderViewPage';
import PointsPage from './pages/points/PointsPage';
import TransactionCostsPage from './pages/transactionCosts/TransactionCostsPage';
import { ErrorProvider } from './shared/context/ErrorProvider';
import VersionUpdateModal from './shared/VersionUpdateModal';
import { theme } from './theme/legacy-theme';

import '../css/index.css';
import AdminPanel from './pages/admin/AdminPanel';
import TermsAndConditions from './pages/agreements/TermsAndConditions';
import DicyPage from './pages/dicy/DicyPage';
import ExplorerPage from './pages/explorer/ExplorerPage';
import ExplorerProofsPage from './pages/explorer/ExplorerProofsPage';
import ExplorerTradesPage from './pages/explorer/ExplorerTradesPage';
import ProofDetailsPage from './pages/explorer/proofDetails/ProofDetailsPage';
import TradeDetailsPage from './pages/explorer/tradeDetails/TradeDetailsPage';
import TraderEpochDetailsPage from './pages/explorer/traderEpochDetails/TraderEpochDetailsPage';
import OptionOrderPage from './pages/optionOrderEntry/OptionOrderPage';
import ChainedOrderDetailsPage from './pages/orderDetails/chainedOrderDetails/ChainedOrderDetailsPage';
import ReferralPage from './pages/referral/ReferralPage';
import AgreementGatedRoute from './routing/AgreementGatedRoute';
import ErrorBoundary from './routing/ErrorBoundary';
import NavBar from './routing/navBar';
import PrivateRoute from './routing/PrivateRoute';
import { TitleProvider } from './shared/context/TitleProvider';
import {
  UserMetadataProvider,
  useUserMetadata,
} from './shared/context/UserMetadataProvider';
import { Loader } from './shared/Loader';
import * as OrderFormAtoms from './pages/dashboard/orderEntry/hooks/useFormReducer';
import * as ChainedOrderFormAtoms from './pages/chainedOrders/hooks/useAtomReducer';
import * as OptionOrderFormAtoms from './pages/optionOrderEntry/hooks/useFormReducer';

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'IBM PLEX MONO',
    },
  },
});

const releaseNotesUrl =
  'https://foul-wavelength-9af.notion.site/TaaS-Release-Notes-2a2ca754c9e24e7e9ee71fc2d00f64ad';

function ComponentTree({ children = null }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <ErrorProvider>{children}</ErrorProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

function App() {
  const { user, version, isRetail, isDev, isMetadataLoading } =
    useUserMetadata();
  const isAuthenticated = user && user.is_authenticated;
  const storedVersionRef = useRef(localStorage.getItem('appVersion'));
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [versionContent, setVersionContent] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    async function checkVersion() {
      const storedVersion = localStorage.getItem('appVersion');
      const isVersionChecked = sessionStorage.getItem('isVersionChecked');
      let response;
      try {
        response = await getVersionData();
      } catch (error) {
        return;
      }

      if (isVersionChecked === 'true' && version === storedVersion) {
        return;
      }

      // If version from backend has changed
      if (version && version !== '0.0.0' && version !== storedVersion) {
        const newVersionContent = response.VERSION_FEATURES;
        setVersionContent(newVersionContent);
        setShowUpdateModal(true);
        storedVersionRef.current = version;
        localStorage.setItem('appVersion', version);
        sessionStorage.setItem('isVersionChecked', 'true');
      }
    }

    checkVersion();
  }, [version, user]);

  return (
    <ComponentTree>
      <Router>
        <TitleProvider>
          <Box height='100vh' minWidth='1440px' width='100%'>
            {!isMetadataLoading ? (
              <>
                <NavBar version={version} />
                <Box
                  marginTop='8px'
                  paddingX='8px'
                  style={{
                    height: 'calc(100% - 78px)',
                    width: 'calc(100% - 16px)',
                  }}
                  sx={{ overflow: 'hidden' }}
                >
                  <ErrorBoundary>
                    <Routes>
                      {isAuthenticated ? (
                        <Route element={<AgreementGatedRoute />}>
                          <Route
                            element={
                              <OrderFormProvider FormAtoms={OrderFormAtoms}>
                                <OrderEntryPage />
                              </OrderFormProvider>
                            }
                            path='/'
                          />
                        </Route>
                      ) : (
                        <Route
                          element={
                            <OrderFormProvider FormAtoms={OrderFormAtoms}>
                              <OrderEntryPage />
                            </OrderFormProvider>
                          }
                          path='/'
                        />
                      )}

                      <Route
                        element={<OrderDetailsPage />}
                        path='/order/:uuid'
                      />

                      <Route
                        element={<MultiOrderDetailsPage />}
                        path='/multi_order/:uuid'
                      />
                      <Route
                        element={<ChainedOrderDetailsPage />}
                        path='/chained_orders/:uuid'
                      />
                      <Route
                        element={<SimpleOrderDetailsPage />}
                        path='/simple_order/:uuid'
                      />
                      <Route
                        element={<OrderSuperDetailPage />}
                        path='/order_detail/:uuid'
                      />

                      {!isRetail && (
                        <Route element={<AdminPanel />} path='/admin_panel' />
                      )}

                      <Route
                        element={<TermsAndConditions />}
                        path='/beta_agreement'
                      />
                      <Route element={<Navigate to='/' />} path='*' />
                      <Route
                        element={<ReferralPage />}
                        path='/referral/:code'
                      />
                      <Route
                        element={
                          <PrivateRoute isAuthenticated={isAuthenticated} />
                        }
                      >
                        <Route element={<AgreementGatedRoute />}>
                          <Route
                            element={
                              <OrderFormProvider
                                FormAtoms={OptionOrderFormAtoms}
                              >
                                <OptionOrderPage />
                              </OrderFormProvider>
                            }
                            path='/enter_option_order'
                          />
                          <Route
                            element={<KeyManagementPage />}
                            path='/key_management'
                          />
                          <Route
                            element={
                              <OrderFormProvider FormAtoms={OrderFormAtoms}>
                                <MultiOrderEntryPage />
                              </OrderFormProvider>
                            }
                            path='/enter_multi_order'
                          />
                          <Route
                            element={
                              <OrderFormProvider
                                FormAtoms={ChainedOrderFormAtoms}
                              >
                                <ChainedOrderEntryPage />
                              </OrderFormProvider>
                            }
                            path='/enter_chained_order'
                          />
                          {isDev && (
                            <Route
                              element={<ExplorerPage />}
                              path='/explorer'
                            />
                          )}
                          <Route
                            element={<ExplorerProofsPage />}
                            path='/explorer/proofs'
                          />
                          <Route
                            element={<ExplorerTradesPage />}
                            path='/explorer/trades'
                          />
                          <Route
                            element={<TradeDetailsPage />}
                            path='/explorer/trades/:id'
                          />
                          <Route
                            element={<ProofDetailsPage />}
                            path='/explorer/proofs/:id'
                          />
                          <Route
                            element={<TraderEpochDetailsPage />}
                            path='/explorer/trader-epoch/:traderId/:epoch'
                          />
                          <Route element={<DicyPage />} path='/dicy' />
                        </Route>
                        <Route element={<AccountSettings />} path='/settings' />
                        <Route
                          element={<OrderViewPage />}
                          path='/view_orders'
                        />
                        <Route
                          element={<AccountBalancePage />}
                          path='/account_balances'
                        />
                        <Route element={<PointsPage />} path='/points' />
                        <Route element={<ReferralPage />} path='/referral' />
                        <Route
                          element={<TransactionCostsPage />}
                          path='/transaction_costs'
                        />
                      </Route>
                    </Routes>
                  </ErrorBoundary>
                </Box>
              </>
            ) : (
              <Loader />
            )}
          </Box>
        </TitleProvider>
      </Router>
      <VersionUpdateModal
        open={showUpdateModal}
        version={version}
        versionContent={versionContent}
        onClose={() => setShowUpdateModal(false)}
        onReadMore={() => {
          window.open(releaseNotesUrl, '_blank');
          setShowUpdateModal(false);
        }}
      />
    </ComponentTree>
  );
}

const dashboardRoot = createRoot(document.getElementById('root'));
dashboardRoot.render(
  <UserMetadataProvider>
    <App />
  </UserMetadataProvider>
);

if (module.hot) {
  module.hot.accept();
}
