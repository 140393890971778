import { abis } from '@/pages/explorer/proofUtils/ProofAbis';
import {
  createEmptyEvent,
  formatRiskEvent,
  formatTradeEvent,
} from '@/pages/explorer/proofUtils/ProofFetchers';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';

function proofCacheKey(id) {
  return `proof_${id}`;
}

/**
 * Hook to fetch and cache proof details from the blockchain
 * @param {string} id - Transaction ID to fetch
 * @param {Object} config - Configuration object containing RPC and contract details
 * @returns {Object} Object containing proofData, eventData, and loading state
 */
function useProofDetails(id, config) {
  const [proofData, setProofData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState({});
  const { showAlert } = useContext(ErrorContext);

  useEffect(() => {
    const cacheKey = proofCacheKey(id);

    // Return cached data if available
    if (cache[cacheKey]) {
      setProofData(cache[cacheKey].proofData);
      setEventData(cache[cacheKey].eventData);
      setLoading(false);
      return;
    }

    const fetchProofData = async ({ rpcUrl, attestationAddress }) => {
      setLoading(true);

      try {
        const provider = new ethers.JsonRpcProvider(rpcUrl);
        const contract = new ethers.Contract(
          attestationAddress,
          abis,
          provider
        );

        const receipt = await provider.getTransactionReceipt(id);
        if (!receipt) {
          throw new Error('Transaction not found');
        }

        const parsedLogs = receipt.logs
          .map((log) => {
            try {
              return contract.interface.parseLog(log);
            } catch (e) {
              return null;
            }
          })
          .filter(Boolean);

        if (parsedLogs.length === 0) {
          throw new Error('No event found in transaction');
        }

        const eventLog = parsedLogs[0];

        let formattedEvent;
        if (eventLog.name === 'AttestedToData') {
          formattedEvent = formatTradeEvent({
            ...eventLog,
            transactionHash: id,
            blockNumber: receipt.blockNumber,
          });
        } else if (eventLog.name === 'AttestedToRisk') {
          formattedEvent = formatRiskEvent({
            ...eventLog,
            transactionHash: id,
            blockNumber: receipt.blockNumber,
          });
        } else {
          throw new Error('Unknown event type');
        }

        // Update state and cache
        setEventData(eventLog);
        setProofData(formattedEvent);
        setCache((prev) => ({
          ...prev,
          [cacheKey]: {
            proofData: formattedEvent,
            eventData: eventLog,
          },
        }));
      } catch (err) {
        const emptyEvent = createEmptyEvent();
        setProofData(emptyEvent);
        setCache((prev) => ({
          ...prev,
          [cacheKey]: {
            proofData: emptyEvent,
            eventData: null,
          },
        }));

        showAlert({
          severity: 'error',
          message: err.message || 'Failed to fetch proof details',
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchProofData(config);
    }
  }, [id, config]);

  return { proofData, eventData, loading };
}

export default useProofDetails;
