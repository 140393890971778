import { AccountsContext } from '@/shared/context/AccountsProvider';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { useTheme } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Grid,
} from '@mui/material';
import { useContext, useMemo } from 'react';
import { selectBasescanConfig } from '../proofUtils/ProofConfig';
import { useProofsCache } from '../proofUtils/useProofsCache';
import DataEventSection from './DataEventSection';
import RiskEventsSection from './RiskEventsSection';
import { insertEllipsis } from '../insertEllipsis';

/**
 * Component that displays detailed information about a trader's epoch, including data and risk attestation events.
 * Shows transaction hashes and detailed event data if the user is authorized to view it.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.traderId - ID of the trader to display details for
 * @param {number|string} props.epoch - Epoch number to display details for
 * @returns {React.ReactElement} Card containing trader epoch details or error message
 */
export default function TraderEpochDetailsTable({ traderId, epoch }) {
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);
  const { isDev } = useUserMetadata();
  const config = selectBasescanConfig(isDev);

  // Use proofs cache instead of trader epoch details
  const { proofs } = useProofsCache();
  const proofDetails = useMemo(() => {
    if (!traderId || !epoch) return null;

    const proof = proofs.find(
      (p) => p.traderId === traderId && Number(p.epoch) === Number(epoch)
    );

    if (!proof) return null;

    return {
      dataEventTxHash: proof.dataEvent?.transactionHash || null,
      riskEventTxHashes: proof.riskEvents?.map((e) => e.transactionHash) || [],
      loading: false,
      error: null,
    };
  }, [proofs, traderId, epoch]);

  // Validate inputs
  if (!traderId) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid trader ID
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!epoch || Number.isNaN(Number(epoch))) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid epoch - must be a number
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!proofDetails) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' variant='h6'>
            No transaction hashes found for this epoch
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const { dataEventTxHash, riskEventTxHashes } = proofDetails;

  return (
    <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <DataEventSection config={config} txHash={dataEventTxHash} />
          </Grid>
          <Grid item md={6} xs={12}>
            <RiskEventsSection config={config} txHashes={riskEventTxHashes} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
