import Typography from '@mui/material/Typography';
import {
  CustomColorStyledTableCell,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { numberWithSpaces, smartRound } from '@/util';

const pnlStyleByValue = (val) => {
  if (val >= 0) {
    return 'success.main';
  }

  return 'error.main';
};

const signByValue = (val) => {
  if (val > 0) {
    return '+';
  }
  if (val < 0) {
    return '-';
  }

  return '';
};

export default function AssetsTableCell({
  column,
  value,
  row,
  cellColor,
  cellRender = StyledTableCell,
}) {
  let CellRender = cellRender;

  if (column.id === 'symbol') {
    CellRender = CustomColorStyledTableCell(cellColor);
  }

  let cellValue = value;
  let color = 'text.primary';

  if (column.number) {
    if (column.id === 'amount') {
      if (row.asset_type === 'mixed') {
        cellValue = '-';
      } else {
        cellValue = smartRound(Number(value));
      }
    } else if (
      column.id === 'unrealized_profit_percentage' ||
      column.id === 'unrealized_profit'
    ) {
      if (row.asset_type === 'token' || row.asset_type === 'mixed') {
        cellValue = '-';
      } else {
        const formattedValue =
          column.id === 'unrealized_profit_percentage'
            ? `${Number(Math.abs(value)).toFixed(2)}%`
            : `$${Number(Math.abs(value)).toFixed(2)}`;
        color = pnlStyleByValue(value);
        cellValue = `${signByValue(value)}${formattedValue}`;
      }
    } else {
      cellValue = `$${numberWithSpaces(Number(value).toFixed(2))}`;
    }
  }

  return (
    <CellRender
      align={column.align}
      key={column.id}
      style={{ whiteSpace: 'nowrap' }}
      width={column.width || '20%'}
    >
      <Typography color={color} variant='body1'>
        {cellValue}
      </Typography>
    </CellRender>
  );
}
