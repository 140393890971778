import { useTheme } from '@emotion/react';
import {
  AccountBalanceWalletRounded,
  AccountCircleRounded,
  Info,
  LogoutOutlined,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LOGOS from '../../images/logos';
import { getPlantStatus, logout } from '../apiServices';
import { LinkMenu } from '../shared/LinkMenu';
import { useUserMetadata } from '../shared/context/UserMetadataProvider';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const loginUrl = '/account/login/';
const signupUrl = '/account/signup/';

function NavBar({ version }) {
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);
  const [infoMenuAnchorEl, setInfoMenuAnchorEl] = React.useState(null);
  const [plantStatus, setPlantStatus] = React.useState('N/A');

  const theme = useTheme();
  const location = useLocation();

  const { isRetail, isDev, user, noAccountsFlag, isDicyEnabled } =
    useUserMetadata();

  const isAuthenticated = user && user.is_authenticated;

  const showNewUserKeyTooltip =
    noAccountsFlag && location.pathname !== '/key_management';

  const handleLogout = async () => {
    if (isAuthenticated) {
      try {
        await logout();
      } catch (error) {
        return;
      }
    }

    localStorage.setItem('user', JSON.stringify({}));
    // replace with navigate when login page is re-written to react
    window.location.href = '/account/login/';
  };

  useEffect(() => {
    const loadPlantStatus = async () => {
      let status = null;
      try {
        status = await getPlantStatus();
      } catch (error) {
        return;
      }
      setPlantStatus(status.status);
    };

    if (isAuthenticated) {
      loadPlantStatus();
    }
  }, []);

  const statusStyles = {
    online: {
      orb: { backgroundColor: theme.palette.success.main },
      text: { color: theme.palette.success.main },
    },
    degraded: {
      orb: { backgroundColor: theme.palette.warning.main },
      text: { color: theme.palette.warning.main },
    },
    offline: {
      orb: { backgroundColor: theme.palette.error.main },
      text: { color: theme.palette.error.main },
    },
    na: {
      orb: { backgroundColor: theme.palette.text.disabled },
      text: { color: theme.palette.text.disabled },
    },
  };

  const renderStatusIndicator = (status) => {
    const getStatusStyles = () => {
      switch (status) {
        case 'Online':
          return statusStyles.online;
        case 'Partially Degraded':
          return statusStyles.degraded;
        case 'Offline':
          return statusStyles.offline;
        default:
          return statusStyles.na;
      }
    };

    if (status === 'N/A') {
      return null;
    }

    const { orb, text } = getStatusStyles();

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '0.5rem',
        }}
      >
        <Box
          sx={{
            width: 9,
            height: 9,
            borderRadius: '50%',
            marginRight: 0.8,
            ...orb,
          }}
        />
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '0.8rem',
            ...text,
          }}
        >
          {status}
        </Typography>
      </Box>
    );
  };

  const handleInfoMenuOpen = (event) => {
    setInfoMenuAnchorEl(event.currentTarget);
  };

  const handleInfoMenuClose = () => {
    setInfoMenuAnchorEl(null);
  };

  const handleAccountMenuOpen = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const tradeMenuItems = [
    { label: 'Spot & Futures', link: '/', disabled: false },
    {
      label: 'Portfolio & Basis',
      link: '/enter_multi_order',
      disabled: !isAuthenticated,
    },
    {
      label: 'Options',
      link: '/enter_option_order',
      disabled: !isAuthenticated,
    },
    {
      label: 'Chained Orders [BETA]',
      link: '/enter_chained_order',
      disabled: !isAuthenticated,
    },
  ];

  return (
    <AppBar
      sx={{
        backgroundColor: isDev ? 'exchangeBalance.3' : '#000000',
        color: theme.palette.text.primary,
        marginLeft: '0',
        marginRight: '0',
        height: '60px',
        position: 'static',
      }}
    >
      <Toolbar>
        <IconButton
          disableFocusRipple
          disableRipple
          color='inherit'
          component={Link}
          edge='start'
          sx={{ marginRight: '2rem' }}
          to='/'
        >
          <img
            alt='Tread Logo'
            src={LOGOS.treadFullDarkSvg}
            style={{ height: 30 }}
          />
        </IconButton>
        <LinkMenu menuButtonText='Trade' menuItems={tradeMenuItems} />

        <Button
          color='inherit'
          component={Link}
          disabled={!isAuthenticated}
          to='/view_orders'
        >
          <Typography
            color={
              !isAuthenticated
                ? theme.palette.text.disabled
                : theme.palette.text.main
            }
          >
            Orders
          </Typography>
        </Button>
        <Button
          color='inherit'
          component={Link}
          disabled={!isAuthenticated}
          to='/transaction_costs'
        >
          <Typography
            color={
              !isAuthenticated
                ? theme.palette.text.disabled
                : theme.palette.text.main
            }
          >
            Analytics
          </Typography>
        </Button>
        {(isRetail || isDev) && (
          <Button
            color='inherit'
            component={Link}
            disabled={!isAuthenticated}
            to='/points'
          >
            <Typography
              color={
                !isAuthenticated
                  ? theme.palette.text.disabled
                  : theme.palette.text.main
              }
            >
              Points
            </Typography>
          </Button>
        )}
        {(isRetail || isDev) && (
          <Button
            color='inherit'
            component={Link}
            disabled={!isAuthenticated}
            to='/referral'
          >
            <Typography
              color={
                !isAuthenticated
                  ? theme.palette.text.disabled
                  : theme.palette.text.main
              }
            >
              Referral
            </Typography>
          </Button>
        )}
        {isDev && (
          <Button
            color='inherit'
            component={Link}
            disabled={!isAuthenticated}
            to='/explorer'
          >
            <Typography
              color={
                !isAuthenticated
                  ? theme.palette.text.disabled
                  : theme.palette.text.main
              }
            >
              Explorer
            </Typography>
          </Button>
        )}
        {isDicyEnabled && (
          <Button
            color='inherit'
            component={Link}
            disabled={!isAuthenticated}
            to='/dicy'
          >
            <Typography
              color={
                !isAuthenticated
                  ? theme.palette.text.disabled
                  : theme.palette.text.main
              }
            >
              Dicy
            </Typography>
          </Button>
        )}
        <Button
          color='inherit'
          component={Link}
          disabled={!isAuthenticated}
          to='/account_balances'
        >
          <Typography
            color={
              !isAuthenticated
                ? theme.palette.text.disabled
                : theme.palette.text.main
            }
          >
            Portfolio
          </Typography>
        </Button>

        <Box sx={{ flexGrow: 1 }} />

        <Typography
          sx={{
            fontSize: '0.75rem',
            color: theme.palette.text.disabled,
            marginLeft: '0.5rem',
            paddingRight: '0.85rem',
            opacity: '0.6',
          }}
        >
          v{version}
        </Typography>

        {renderStatusIndicator(plantStatus)}

        <NoMaxWidthTooltip
          arrow
          open={showNewUserKeyTooltip}
          placement='left'
          slotProps={{
            tooltip: {
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'rgb(92, 92, 92)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
                typography: 'h6', // Adjust typography variant as needed
                padding: '10px 15px', // Adjust padding to accommodate larger text
              },
            },
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5],
                  },
                },
              ],
            },
          }}
          title='Add keys to start trading!'
        >
          <IconButton
            color='inherit'
            component={Link}
            disabled={!isAuthenticated}
            to='/key_management'
          >
            <AccountBalanceWalletRounded sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </NoMaxWidthTooltip>
        <IconButton color='inherit' onClick={handleInfoMenuOpen}>
          <Info sx={{ fontSize: '1.5rem' }} />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={infoMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id='info-menu'
          open={Boolean(infoMenuAnchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleInfoMenuClose}
        >
          <MenuItem
            onClick={() => {
              window.open('https://tread-labs.gitbook.io/api-docs', '_blank');
              handleInfoMenuClose();
            }}
          >
            <Typography>Documentation</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                'https://foul-wavelength-9af.notion.site/TaaS-Release-Notes-2a2ca754c9e24e7e9ee71fc2d00f64ad',
                '_blank'
              );
              handleInfoMenuClose();
            }}
          >
            <Typography>Release Notes</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                'https://foul-wavelength-9af.notion.site/Terms-of-Service-7eeb5222e8c845c2b411601ccc729f99',
                '_blank'
              );
              handleInfoMenuClose();
            }}
          >
            <Typography>Terms of Service</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                'https://foul-wavelength-9af.notion.site/Privacy-Policy-29f3010cd06141929ddbd6bfc2ab98a7',
                '_blank'
              );
              handleInfoMenuClose();
            }}
          >
            <Typography>Privacy Policy</Typography>
          </MenuItem>
        </Menu>

        {isAuthenticated && (
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              color='inherit'
              size='large'
              onClick={handleAccountMenuOpen}
            >
              <AccountCircleRounded sx={{ fontSize: '1.5rem' }} />
            </IconButton>
            <Menu
              keepMounted
              anchorEl={accountMenuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              id='account-settings-menu'
              open={Boolean(accountMenuAnchorEl)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleAccountMenuClose}
            >
              <MenuItem
                component={Link}
                to='/settings'
                onClick={handleAccountMenuClose}
              >
                <Typography>Account & Settings</Typography>
              </MenuItem>
              {user.is_superuser && !isRetail && (
                <MenuItem
                  component={Link}
                  to='/admin_panel'
                  onClick={handleAccountMenuClose}
                >
                  <Typography>Admin Panel</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
        {isAuthenticated ? (
          <IconButton color='inherit' onClick={handleLogout}>
            <LogoutOutlined sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        ) : (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              marginLeft: '0.5rem',
            }}
          >
            <Button
              href={loginUrl}
              size='small'
              sx={{ backgroundColor: theme.palette.primary.dark2 }}
              variant='contained'
            >
              <Typography color='text'>Login</Typography>
            </Button>
            {isRetail && (
              <Button
                color='primary'
                href={signupUrl}
                size='small'
                variant='contained'
              >
                <Typography color={theme.palette.text.offBlack}>
                  Sign up
                </Typography>
              </Button>
            )}
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
