import { createTheme } from '@mui/material/styles';
import typography from './typography';
import palette from './colors';
import breakpoints from './breakpoints';

const theme = createTheme({
  typography: {
    allVariants: {
      color: 'rgb(230, 230, 230)',
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    fontFamily: ['IBM PLEX MONO'].join(','),
    h1: {
      fontSize: typography.fontSizes[1100],
      lineHeight: typography.lineHeights[1100],
      fontWeight: typography.fontWeights.weight300,
    },
    h2: {
      fontSize: typography.fontSizes[1000],
      lineHeight: typography.lineHeights[1000],
      fontWeight: typography.fontWeights.weight300,
    },
    h3: {
      fontSize: typography.fontSizes[900],
      lineHeight: typography.lineHeights[900],
      fontWeight: typography.fontWeights.weight300,
    },
    h4: {
      fontSize: typography.fontSizes[800],
      lineHeight: typography.lineHeights[800],
      fontWeight: typography.fontWeights.weight300,
    },
    h5: {
      fontSize: typography.fontSizes[700],
      lineHeight: typography.lineHeights[700],
      fontWeight: typography.fontWeights.weight300,
    },
    h6: {
      fontSize: typography.fontSizes[600],
      lineHeight: typography.lineHeights[600],
      fontWeight: typography.fontWeights.weight300,
    },
    subtitle1: {
      fontSize: typography.fontSizes[500],
      lineHeight: typography.lineHeights[500],
      fontWeight: typography.fontWeights.weight300,
    },
    button: {
      textTransform: 'none',
      fontSize: typography.fontSizes[400],
      fontWeight: typography.fontWeights.weight400,
      lineHeight: typography.lineHeights[400],
    },
    body1: {
      fontSize: typography.fontSizes[400],
      lineHeight: typography.lineHeights[400],
      fontWeight: typography.fontWeights.weight400,
    },
    body2: {
      fontSize: typography.fontSizes[300],
      lineHeight: typography.lineHeights[300],
      fontWeight: typography.fontWeights.weight400,
    },
    small1: {
      fontSize: typography.fontSizes[200],
      lineHeight: typography.lineHeights[200],
      fontWeight: typography.fontWeights.weight400,
    },
    small2: {
      fontSize: typography.fontSizes[100],
      lineHeight: typography.lineHeights[100],
      fontWeight: typography.fontWeights.weight400,
    },
  },
  palette: {
    mode: 'dark',
    text: {
      primary: palette.base.white,
      subtitle: palette.grey[300],
      dark: palette.grey[500],
      disabled: palette.grey[300],
    },
    primary: {
      transparent: palette.brand[0],
      light: palette.brand[400],
      main: palette.brand[500],
      dark: '#ffad591A',
    },
    success: {
      main: '#0ecb81', // temporarily match green of rest of legacy app
    },
    error: {
      main: palette.error[500],
    },
    background: {
      base: palette.black[600],
      container: palette.black[800],
    },
    portfolioChart: {
      line: palette.brand[500],
      areaGradientTop: 'rgba(244, 163, 69, 0.3)',
      areaGradientBottom: 'rgba(20,20,20, 0.2)',
      ticks: palette.grey[400],
    },
    exchangeBalance: {
      1: '#2E0038',
      2: '#5D2C6F', // Interpolated between #2E0038 and #C5EBC3
      3: '#8A5989', // Interpolated between #C5EBC3 and #B7C8B5
      4: '#9F6C7F', // Interpolated between #B7C8B5 and #A790A5
      5: '#B25D76', // Interpolated between #A790A5 and #CC7B2F
      6: '#CF8A47', // Interpolated between #CC7B2F and #C5EBC3
      7: '#D8B5A2', // Interpolated between #C5EBC3 and #B7C8B5
      8: '#E4D3A4', // Interpolated between #B7C8B5 and #CC7B2F
    },
  },
  breakpoints: {
    values: breakpoints,
  },
  spacing: 4,
  button: {
    textTransform: 'none',
  },
  fontFamily: ['IBM PLEX MONO'].join(','),
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[950],
          padding: 0,
          height: '100%',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: 'calc(100% - 32px)',
          padding: '16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    StyledTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: palette.base.black,
        },
      },
    },
  },
});

export { theme };
