/**
 * Shows an alert message using the error context
 * @param {Object} params - The alert parameters
 * @param {('error'|'warning'|'info'|'success')} params.severity - The severity level of the alert
 * @param {string} params.message - The message to display in the alert
 * @param {Function} params.setHasError - Function to set error state
 * @param {Function} params.setErrorContent - Function to set error content
 * @example
 * // Show error alert
 * showAlert({
 *   severity: 'error',
 *   message: 'Failed to fetch data',
 *   setHasError: setHasError,
 *   setErrorContent: setErrorContent
 * });
 * 
 * // Show success alert
 * showAlert({
 *   severity: 'success', 
 *   message: 'Data saved successfully',
 *   setHasError: setHasError,
 *   setErrorContent: setErrorContent
 * });
 */
export const showAlert = ({
  severity,
  message,
  setHasError,
  setErrorContent,
}) => {
  setErrorContent({ severity, message });
  setHasError(true);
};
