/**
 * Configuration object for Basescan RPC endpoints and contract addresses
 * @type {{
 *   development: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string
 *   },
 *   production: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string
 *   },
 *   defaults: {
 *     numberOfBlocks: number,
 *     retry: number
 *   }
 * }}
 */
const BASESCAN_CONFIG = {
  development: {
    rpcUrl: 'https://sepolia.base.org',
    explorerUrl: 'https://sepolia.basescan.org',
    attestationAddress: '0xB4f9A1f1347b7D8eb97dC70672576BB96E0510e0',
    accessAddress: '0x40D245668ab0df4619Af6467e3036Cb68404083b',
  },
  production: {
    rpcUrl: 'https://mainnet.base.org',
    explorerUrl: 'https://basescan.org',
    attestationAddress: '0xC31c7663873d36bC63bA28df4D40D0102F73D1B5',
    accessAddress: '0x40D245668ab0df4619Af6467e3036Cb68404083b',
  },
  defaults: {
    numberOfBlocks: 50000,
    retry: 3,
  },
};

/**
 * Selects the appropriate Basescan RPC URL based on development environment
 * @param {boolean} isDev - Whether the app is running in development environment
 * @returns {Object} The Basescan config object with properties:
 *   @property {string} rpcUrl - The RPC URL for either testnet or mainnet
 *   @property {string} explorerUrl - The explorer URL for either testnet or mainnet
 *   @property {string} attestationAddress - The contract address
 *   @property {number} numberOfBlocks - Number of blocks to query
 *   @property {number} retry - Number of retry attempts
 *   @property {number} paginationNumber - Pagination offset
 */
export const selectBasescanConfig = (isDev = false, paginationNumber = 0) => {
  const env = isDev ? 'development' : 'production';
  const config = BASESCAN_CONFIG[env];

  console.debug(
    '[selectBasescanConfig]',
    `Environment: ${env}`,
    `RPC URL: ${config.rpcUrl}`,
    `Explorer URL: ${config.explorerUrl}`,
    `Contract: ${config.attestationAddress}`,
    `Pagination: ${paginationNumber}`
  );

  return {
    ...config,
    ...BASESCAN_CONFIG.defaults,
    paginationNumber,
  };
};
