import { AccountsProvider } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { replaceBigInts } from '@/shared/bigIntUtils';
import TraderEpochDetailsTable from './TraderEpochDetailsTable';
import { insertEllipsis } from '../insertEllipsis';

/**
 * Page component for displaying trader epoch details
 */
export default function TraderEpochDetailsPage() {
  const { showAlert } = useContext(ErrorContext);
  const navigate = useNavigate();
  const { traderId, epoch } = useParams();

  useEffect(() => {
    if (!traderId || !epoch) {
      showAlert({
        severity: 'error',
        message: 'Invalid trader ID or epoch',
      });
      return;
    }

    // Validate epoch is a number
    if (Number.isNaN(Number(epoch))) {
      showAlert({
        severity: 'error',
        message: 'Invalid epoch - must be a number',
      });
    }
  }, [traderId, epoch, showAlert, navigate]);

  return (
    <Box
      spacing={2}
      sx={{
        minHeight: '100vh',
        width: '80%',
        margin: '0 auto',
        paddingBottom: '2rem',
      }}
    >
      <Box sx={{ padding: '1rem 0' }}>
        <Button
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2 }}
          onClick={() => navigate('/explorer/proofs')}
        >
          Back to Proofs
        </Button>
        <Typography
          fontFamily='Jost'
          fontSize={28}
          fontWeight={400}
          variant='h2'
        >
          Trader Epoch Details
        </Typography>
        <Typography sx={{ mt: 1 }} variant='subtitle1'>
          Trader ID: {insertEllipsis(traderId)} | Epoch:{' '}
          {Number(epoch).toLocaleString()}
        </Typography>
      </Box>
      <AccountsProvider>
        <TraderEpochDetailsTable epoch={epoch} traderId={traderId} />
      </AccountsProvider>
    </Box>
  );
}
