import { AccountsProvider } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { Box } from '@mui/material';
import { useContext } from 'react';
import ExplorerTradesTable from './ExplorerTradesTable';

function ExplorerTradesPageContent() {
  const { setHasError, setErrorContent } = useContext(ErrorContext);

  return (
    <Box
      spacing={2}
      sx={{
        height: '850px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <ExplorerTradesTable
        pageView={false}
        setErrorContent={setErrorContent}
        setHasError={setHasError}
      />
    </Box>
  );
}

export default function ExplorerTradesPage() {
  return (
    <AccountsProvider>
      <ExplorerTradesPageContent />
    </AccountsProvider>
  );
}
