/**
 * Array of ABI definitions for attestation events
 * Used for decoding event logs from the Attestations contract
 *
 * @typedef {Object} DataRecord
 * @property {string} merkleRoot - Merkle root hash of the data
 * @property {string} cid - Content identifier for IPFS
 *
 * @typedef {Object} RiskRecord
 * @property {string} value - Risk value as a number string
 *
 * @typedef {Object} AttestedToDataEvent
 * @property {string} traderId - Unique identifier for the trader
 * @property {string} epoch - Timestamp epoch
 * @property {string} attester - Address of the attesting party
 * @property {DataRecord} record - The data record containing merkle root and CID
 *
 * @typedef {Object} AttestedToRiskEvent
 * @property {string} traderId - Unique identifier for the trader
 * @property {string} epoch - Timestamp epoch
 * @property {string} parameterId - Identifier for the risk parameter
 * @property {string} attester - Address of the attesting party
 * @property {RiskRecord} record - The risk record containing value
 */
export const abis = [
  {
    /**
     * Event emitted when an attester submits data attestation
     * Contains the trader ID, epoch, attester address, and data record with merkle root and CID
     */
    type: 'event',
    name: 'AttestedToData',
    inputs: [
      {
        name: 'traderId',
        type: 'bytes32',
        indexed: true,
        internalType: 'bytes32',
      },
      {
        name: 'epoch',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'attester',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'record',
        type: 'tuple',
        indexed: false,
        internalType: 'struct Attestations_Types.DataRecord',
        components: [
          {
            name: 'merkleRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'cid',
            type: 'string',
            internalType: 'string',
          },
        ],
      },
    ],
  },
  {
    /**
     * Event emitted when an attester submits risk attestation
     * Contains the trader ID, epoch, parameter ID, attester address, and risk record with value
     */
    type: 'event',
    name: 'AttestedToRisk',
    inputs: [
      {
        name: 'traderId',
        type: 'bytes32',
        indexed: true,
        internalType: 'bytes32',
      },
      {
        name: 'epoch',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'parameterId',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'attester',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'record',
        type: 'tuple',
        indexed: false,
        internalType: 'struct Attestations_Types.RiskRecord',
        components: [
          {
            name: 'value',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
  },
];
