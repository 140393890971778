import { AccountsProvider } from '@/shared/context/AccountsProvider';
import TradeDetailsTable from './TradeDetailsTable';

function TradeDetailsPageContent() {
  return <TradeDetailsTable />;
}

export default function TradeDetailsPage() {
  return (
    <AccountsProvider>
      <TradeDetailsPageContent />
    </AccountsProvider>
  );
}
